import React, { Component } from "react";

import ContentRotator from "./contentRotator";

class ContentRotatorContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contents: this.props.contents,
      transitioning: false,
      transitioningIn: false,
      transitioningOut: false,
      transitioningTo: null
    };

    this.goToNext = this.goToNext.bind(this);
    this.moveTopItemToBottom = this.moveTopItemToBottom.bind(this);
    this.moveBottomItemToTop = this.moveBottomItemToTop.bind(this);
    this.finishTransition = this.finishTransition.bind(this);
    this.goToPrevious = this.goToPrevious.bind(this);
  }

  componentDidUpdate() {
    // check if transitioning to next
    if (
      this.state.transitioning &&
      this.state.transitioningOut &&
      this.state.transitioningTo === "next"
    ) {
      window.setTimeout(this.moveBottomItemToTop, 300);
    }
    // check it starting transition to previous
    if (
      this.state.transitioning &&
      this.state.transitioningOut &&
      this.state.transitioningTo === "previous"
    ) {
      window.setTimeout(this.moveTopItemToBottom, 300);
    }
    // check if transition is done
    if (this.state.transitioning && this.state.transitioningIn) {
      window.setTimeout(this.finishTransition, 300);
    }
  }

  finishTransition() {
    this.setState(() => ({
      transitioning: false,
      transitioningIn: false,
      transitioningOut: false,
      transitioningTo: null
    }));
  }

  moveBottomItemToTop() {
    this.setState(prevState => {
      const oldContents = prevState.contents; // save reference to oldContents
      const lastItem = oldContents.pop(); // remove item from end of array and save to lastItem
      const newContents = oldContents; // create copy of oldContents
      newContents.unshift(lastItem); // push removed item to beginning of array
      return {
        contents: newContents,
        transitioningOut: false,
        transitioningIn: true
      };
    });
  }

  moveTopItemToBottom() {
    this.setState(prevState => {
      const oldContents = prevState.contents; // save reference to oldContents
      const firstItem = oldContents.shift(); // remove item from front of array and save to lastItem
      const newContents = oldContents; // create copy of oldContents
      newContents.push(firstItem); // push removed item to end of array
      return {
        contents: newContents,
        transitioningOut: false,
        transitioningIn: true
      };
    });
  }

  goToNext() {
    //   start transition
    this.setState(prevState => {
      if (prevState.transitioning === true) {
        return;
      }
      return {
        transitioning: true,
        transitioningOut: true,
        transitioningTo: "next"
      };
    });
  }

  goToPrevious() {
    if (this.state.transitioning === true) {
      return;
    }
    //   start transition
    this.setState({
      transitioning: true,
      transitioningOut: true,
      transitioningTo: "previous"
    });
  }

  render() {
    return (
      <ContentRotator
        contents={this.state.contents}
        // display state
        transitioning={this.state.transitioning}
        transitioningIn={this.state.transitioningIn}
        transitioningOut={this.state.transitioningOut}
        transitioningTo={this.state.transitioningTo}
        // methods
        goToNext={this.goToNext}
        goToPrevious={this.goToPrevious}
      />
    );
  }
}
export default ContentRotatorContainer;
