// Node Modules
import React from "react";
import { withSiteData } from "react-static";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import renderHTML from "react-render-html";

// Global Components
import VideoPlayer from "components/videoPlayer/videoPlayer";

// Local Components
import ContentRotator from "./components/contentRotator/contentRotatorContainer";
import Stream from "./components/stream/stream";

// Local CSS
import "./home.css";
import "./home-mobile.css";
import "./youTubeStream.css";

// edit test

export default withSiteData(({ websiteConfiguration }) => {
  const renderVideoLoop = () => (
    <video
      src={websiteConfiguration.fields.homepageVideoLoop.fields.file.url}
      autoPlay
      loop
      muted
    />
  );

  const renderVideoPlayer = () => (
    <div className="videoPlayerContainer">
      <div className="videoPlayerContainerInner">
        <VideoPlayer
          src={websiteConfiguration.fields.homepageVideoPlayer.fields.file.url}
          poster={
            websiteConfiguration.fields.homepageVideoPlayerPoster.fields.file
              .url
          }
        />
      </div>
    </div>
  );

  const renderContentRotator = () => (
    <ContentRotator
      contents={websiteConfiguration.fields.contentRotatorContent}
    />
  );

  const renderYouTubeStream = () => (
    <div className="youtubeStreamContainer">
      <div className="youtubeStream">
        {/* STREAM URL REPLACEMENT */}
        <Stream streamUrl={websiteConfiguration.fields.youTubeStreamUrl} />
        {/* STREAM URL REPLACEMENT */}
      </div>
    </div>
  );

  const renderContent = () => {
    switch (websiteConfiguration.fields.homepageMode) {
      case "YouTube Stream":
        return renderYouTubeStream();
      case "Video Loop":
        return renderVideoLoop();
      case "Video Player":
        return renderVideoPlayer();
      case "Content Rotator":
        return renderContentRotator();
      default:
        return renderVideoLoop();
    }
  };

  return (
    <div>
      <div className="homepage">
        <div className="brandInfo">
          {renderHTML(
            documentToHtmlString(websiteConfiguration.fields.homepageTextRich)
          )}
        </div>
        {renderContent()}
      </div>
    </div>
  );
});
