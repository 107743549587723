import React from "react";

import "./stream.css";
import "./embed-responsively.css";

class Stream extends React.Component {
  constructor(props) {
    super(props);

    this.streamUrl = props.streamUrl;
  }

  componentDidMount() {}

  render() {
    return (
      <div className="embed-container">
        <iframe
          id="stream"
          title="unique"
          src={`${
            this.streamUrl
          }?controls=1&modestbranding=1&showinfo=0&autohide=1&rel=0&autoplay=1&enablejsapi=1`}
          frameBorder="0"
          allowFullScreen
          autoPlay
        />
        <script src="https://www.youtube.com/iframe_api" />
      </div>
    );
  }
}

export default Stream;
