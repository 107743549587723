import React, { Component } from "react";
import Swipe from "react-easy-swipe";

import Button from "components/button/button";
import Image from "components/image/image";
import Link from "components/link/link";

import "./contentRotator.css";
import "./contentRotatorMobile.css";

class ContentRotator extends Component {
  constructor(props) {
    super(props);
    this.goToNextInterval = undefined;
    this.generateContent = this.generateContent.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.goToNextInterval = window.setInterval(this.props.goToNext, 3000);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.clearInterval(this.goToNextInterval);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key === "ArrowRight" || event.key === "ArrowUp") {
      this.props.goToNext();
    } else if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
      this.props.goToPrevious();
    }
  }

  generateContent(contents) {
    return contents.map((content, index) => {
      let applyToIndex;
      let className = "";
      if (this.props.transitioningIn && this.props.transitioningTo === "next") {
        applyToIndex = 0;
        className += "transitioningIn";
      } else if (
        this.props.transitioningIn &&
        this.props.transitioningTo === "previous"
      ) {
        applyToIndex = contents.length - 1;
        className += "transitioningIn";
      } else if (
        this.props.transitioningOut &&
        this.props.transitioningTo === "next"
      ) {
        applyToIndex = contents.length - 1;
        className += "transitioningOut";
      } else if (
        this.props.transitioningOut &&
        this.props.transitioningTo === "previous"
      ) {
        applyToIndex = 0;
        className += "transitioningOut";
      }

      switch (content.fields.type) {
        case "Image":
          return (
            <Link
              to={content.fields.link}
              key={index}
              className={index === applyToIndex ? className : ""}
            >
              <Image src={content.fields.asset.fields.file.url} />
            </Link>
          );
        default:
          return (
            <Link
              to={content.fields.link}
              key={index}
              className={index === applyToIndex ? className : ""}
            >
              <div className="buttonContainer">
                <Button color="orange">
                  <h1>{content.fields.type}</h1>
                </Button>
              </div>
            </Link>
          );
      }
    });
  }

  render() {
    return (
      <Swipe
        className="contentRotator"
        onSwipeLeft={this.props.goToPrevious}
        onSwipeRight={this.props.goToNext}
      >
        <div className="controls">
          <div className="previous" onClick={this.props.goToPrevious} />
          <div className="next" onClick={this.props.goToNext} />
        </div>
        {this.generateContent(this.props.contents)}
      </Swipe>
    );
  }
}
export default ContentRotator;
