import React, { Component } from "react";

import "./button.css";

class Button extends Component {
  render() {
    return (
      <div
        className={`button ${this.props.color || "gray"} ${this.props
          .selected === true && "selected"}`}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    );
  }
}
export default Button;
