import React, { Component } from "react";
import {
  Player,
  ControlBar,
  BigPlayButton,
  CurrentTimeDisplay,
  TimeDivider,
  VolumeMenuButton,
  LoadingSpinner
} from "video-react";

import "video-react/dist/video-react.css"; // import css
import "./videoPlayer.css"; // custom css

class VideoPlayer extends Component {
  render() {
    return (
      <Player fluid={false} playsInline={false} poster={this.props.poster}>
        <source src={this.props.src} />
        <LoadingSpinner />

        <BigPlayButton position="center" />
        <ControlBar>
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <VolumeMenuButton />
        </ControlBar>
      </Player>
    );
  }
}
export default VideoPlayer;
